.home {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      max-height: 100vh;

}

.top-panel {
    padding: 10px 20px;
    background: linear-gradient(90deg, rgba(77,2,225,1) 0%, rgba(141,44,226,1) 100%);
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: bold;
    border-bottom: 0.5px solid rgba(190, 190, 190, 0.164);
}

.app-container {
    display: flex;
}

.app-content {
    width: 100%;
    box-sizing: border-box;
}
