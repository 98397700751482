.App {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  max-width: 100vw;
 display: flex;
 flex-direction: column;
 align-items: center;
 
}


