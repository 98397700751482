.form-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login-form {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    line-height: 50%;
    box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
-webkit-box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
-moz-box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
}

.form-logo {
    width: 150px;
    align-self: center;
}

.login-title {
    color: #4A00E0;
}

.login-inputs {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    
}

.login-label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #4A00E0;
    
}

.login-inputs input {
    height: 30px;
    padding-left: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #4A00E0;
}

.login-inputs input:focus {
    outline: none;
    border: 1px solid rgba(141,44,226,1);
}

.login-btn {
    padding: 10px;
    background: linear-gradient(90deg, rgba(77,2,225,1) 0%, rgba(141,44,226,1) 100%);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-weight: 24px;
    cursor: pointer;
    font-size: 20px;
}

    .forgot-btn {
        background-color: transparent;
        margin-top: 24px;
        border: none;
        text-decoration: underline;
        text-underline-offset: 5px;
        cursor: pointer;
    }

.error {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

