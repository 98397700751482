.form-audio-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}
.login-audio-form {
    padding: 30px 20px;
    max-width: 700px;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    line-height: 50%;
    box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
    -webkit-box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
    -moz-box-shadow: 5px 5px 13px 1px rgba(97,97,97,0.3);
    margin: 50px auto;
}

.subForm {
    margin-top: 40px;
}

.form-logo {
    width: 150px;
    align-self: center;
}

.login-audio-inputs {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.login-audio-label {
    font-weight: bold;
}

.login-audio-inputs input {
    height: 30px;
    padding-left: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid black;
    box-sizing: border-box;
}

.input-file {
    height: auto !important;
    padding-left: 0px !important;
}

.login-audio-inputs input:focus {
    outline: none;
    border: 1px solid rgba(77,2,225,1);
}

.login-audio-btn {
    padding: 10px;
    background: linear-gradient(90deg, rgba(77,2,225,1) 0%, rgba(141,44,226,1) 100%);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    font-weight: 24px;
    cursor: pointer;
}

.audio-radios {
    display: flex;
    height: 20px;
    margin: 10px 0px;
}

.audio-radios input {
    height: auto !important;
}

.types-select {
    margin-bottom: 40px;
    height: 30px;
    border-radius: 4px;
}

.input-group {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin: 0px 5px;
    height: auto !important;
    min-height: 100px;
}

.input-group label {
    height: 20px;
}

.upload-file {
    padding: 10px;
    margin-top: 20px;
    background: linear-gradient(90deg,rgba(77,2,225,1) 0%, rgba(141,44,226,1) 100%);
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error {
    color: red;
    font-size: 20px;
    font-weight: bold;
}


