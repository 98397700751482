@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Merriweather:wght@300&display=swap');
body {
  margin: 0;
  /* background: linear-gradient(90deg, #11141b 0%, #20202d 100%); */
}

h1,h2,h3,h4,h5,button {
  font-family: 'Merriweather', serif;
}

p, a, label  {
  font-family: 'Lato', sans-serif;
}

