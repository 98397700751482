.left-panel {
    width: 30%;
    max-width: 350px;
    height: 100vh;
    border-right: 0.5px solid rgba(190, 190, 190, 0.164);
}

.left-list {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0px;
    cursor: pointer;
    margin-block-end: 0px;
    margin-block-start: 0px;
}

.left-list {
    list-style-type: none;
}
.left-list a {
    padding: 20px 15px;
    border-bottom: 0.5px solid rgb(241, 241, 241);
}

.left-list a:hover {
    background-color: rgba(226, 226, 226, 0.774);
}

.left-list a {
    display: block;
    height: 100%;
    text-decoration: none;
    color: #4A00E0;
    font-weight: bold;
    font-size: 18px;
}

a i {
    margin-right: 15px;
}