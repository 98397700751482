.users-stats {
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
}

.users-numbers {
    display: flex;
    
}

.users-numbers h2 {
    color: #4A00E0;
    margin-right: 40px;
    font-size: 20px;
}

.users-numbers h2 span {
    background-color: #4A00E0;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    border-radius: 8px;
    box-sizing: border-box;
}

.users-list {
    width: 100%;
    padding: 40px 0px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(161, 151, 151, 0.164);

}


.users-rows {
    height: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.users-headings {
    border-top: 0.5px solid rgba(161, 151, 151, 0.164);
    display: flex;
    justify-content: space-between;
}

.heading {
    width: 20%;
}

.heading p {
    color: #4A00E0;
    font-size: 20px;
    font-weight: bold;
}

.user-row {
    border-top: 0.5px solid rgba(190, 190, 190, 0.164);
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.user-cell {
    width: 20%;
    padding: 20px 0px;

}

.user-cell p {
    color: #4A00E0;
    font-weight: bold;
}
